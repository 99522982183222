import Web3 from 'web3'
import USDT from './bnbUsdt.json'
import DAPP from './dapp.json'
import { Toast } from 'vant';
// const { ethers } = require("ethers");
//正式
const ADDRESS_DAPP ="0x09EB0De8c101C8A763652B7dfb91424bB4Cac353"
const ADDRESS_ARB ="0x55d398326f99059fF775485246999027B3197955"
// const ADDRESS_MC ="0x21113deE375Dcb1BDf929861E28c4e5C2F74B0bb"
const ADDRESS_MC="0xa4e11cC3Ee9d71567555615DDD23Bf6FB4BFa83e"

let ethereum = window.ethereum
// const provider = new ethers.providers.Web3Provider(window.ethereum);
// const signer = provider.getSigner();
    let i = setInterval(() => {
    if ((typeof window.ethereum) !== 'undefined') {
        ethereum = window.ethereum    
          
        handleWatch()  
        clearInterval(i)  
    }
    }, 1000);

export async function set(){
    ethereum = window.ethereum
}


export async function handleWatch () {
    if (!window.ethereum) {
        console.log('Metamask is not installed!')
        return false
      }
    window.ethereum.on('accountsChanged',  (accounts) => {
      if(accounts[0]){
        // let web3s = new Web3(ethereum);
        // let msg = web3s.utils.keccak256('授权签名');
        // let ht= await web3s.eth.sign(msg, accounts[0])
        // .then(res=>{return res});  
        // if(ht){
      sessionStorage.setItem('userWallet',accounts[0])  
    //   store.commit("userWallet", accounts[0]);  
    }else{
        sessionStorage.removeItem('userWallet')
      }     
    })
  }
export async function connectTron() {
    let web3s = new Web3(ethereum);
    // console.log(web3s.utils.toDecimal(ethereum.chainId),ethereum.chainId);
    if (web3s.utils.toDecimal(ethereum.chainId)!='0x38') {

        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{
                    chainId: Web3.utils.numberToHex(56), // 目标链ID
                }]
            })
            return true
        } catch (e) {
            if (e.code == 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: Web3.utils.numberToHex(56), // 目标链ID
                            chainName: 'Arbitrum One',
                            nativeCurrency: {
                                name: 'BNB',
                                symbol: 'BNB',
                                decimals: 18
                            },
                            rpcUrls: ['https://arbitrum-mainnet.infura.io'], // 节点
                            blockExplorerUrls: ['https://explorer.arbitrum.io']
                        }]
                    })
                    return true
                } catch (ee) {
                    console.log('//---------', ee);

                }
            } else if (e.code === 4001) return false
        }    
    }
    const eth_requestAccounts = await ethereum.request({ method: "eth_requestAccounts" });
    // store.commit("userWallet", eth_requestAccounts[0]);
    return eth_requestAccounts
}
export function getHoder() {
    let run = async () => {
          let web3s = new Web3("https://bscrpc.com");
          const unioContract =await new web3s.eth.Contract(USDT, ADDRESS_ARB); 
            const events = await unioContract.getPastEvents('Transfer', { fromBlock: 0, toBlock: 'latest' }); 
        //   console.log(events,'limit exceeded');
            const holders = new Set(); 
            events.forEach((event) => { 
                holders.add(event.returnValues.to); }); 
            console.log(`Total holders: ${holders.size}`);         
          let result = holders.size;
         return result 
    };
    return run();
  }
  export function getCanBind(address) {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.canBind(address).call();
         return result
    };
    return run();
  }
    // 查询时间
 export function getTime1() {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.time1().call();
         return result
    };
    return run();
  }
  
//   用户当前可领取的能量
export function getAvailableEnergy(address) {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.getAvailableEnergy(address).call();
          if(result){
            return  web3s.utils.fromWei(result+'', 'ether');
        }else{
            return 0
        }
    };
    return run();
  }
//   用户提现可领取的能量
export function getWithdrawEnergy(address) {
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
        // let amount = web3s.utils.toWei(num + '','ether');
        let gas = await web3s.eth.getGasPrice();
        let result = await unioContract.methods.withdrawEnergy().send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
         return false
      });
      if(result){
        return result.transactionHash
    }else{
        return false
    }
    };
    return run();
  }
//能量池金额   
export function getEnergyPool() {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.getEnergyPool().call();
          if(result){
            return  web3s.utils.fromWei(result+'', 'ether');
        }else{
            return 0
        }
    };
    return run();
  }
  export function getEnergy(address) {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.energys(address).call();
         return result
    };
    return run();
  }


  export function getTime2() {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.time2().call();
         return result
    };
    return run();
  }
  export function getTime3() {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.time3().call();
         return result
    };
    return run();
  }
  export function getTime4() {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.time4().call();
         return result
    };
    return run();
  }


   // 查询代币是否授权
 export function getMyLp(address) {    
    let run = async () => {
         ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP, {
              from:address
          });
          let result = await unioContract.methods.userInfos(address).call();
        //   console.log(result,'result-------');
          let dayRewardMico=web3s.utils.fromWei(result.dayRewardMico +'', 'ether');
          let dayRewardUsdt=result.dayRewardUsdt/10**18;
          let totalRewardMico=web3s.utils.fromWei(result.totalRewardMico +'', 'ether');
          let totalRewardUsdt=result.totalRewardUsdt/10**18;
         return {dayRewardMico,dayRewardUsdt,totalRewardMico,totalRewardUsdt} 
    };
    return run();
  }
 // 查询代币是否授权
 export function getProved(address) {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(USDT, ADDRESS_MC, {
              from:address
          });
          let result = await unioContract.methods.allowance(address,ADDRESS_DAPP).call()/(10**18);
         return result 
    };
    return run();
  }
  export function getProveds(address) {
    let run = async () => {
        ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(USDT, ADDRESS_ARB, {
              from:address
          });
          let result = await unioContract.methods.allowance(address,ADDRESS_DAPP).call()/(10**18);
         return result 
    };
    return run();
  }
  
 // 授权USDT
 export function toApprove(address,num) {
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(USDT, ADDRESS_MC);  
        let amount = web3s.utils.toWei(num + '','ether');
        let gas = await web3s.eth.getGasPrice();
        console.log(gas);
        let result = await unioContract.methods.approve(ADDRESS_DAPP, amount).send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
         return false
      });
      if(result){
        return result.transactionHash
    }else{
        return false
    }
    };
    return run();
  }
 // 授权ARB
 export function toApproves(address,num) {
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(USDT, ADDRESS_ARB);  
        let amount = web3s.utils.toWei(num + '','ether');
        let gas = await web3s.eth.getGasPrice();
        console.log(gas);
        let result = await unioContract.methods.approve(ADDRESS_DAPP, amount).send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
         return false
      });
      if(result){
        return result.transactionHash
        }else{
            return false
        } 
    };
    return run();
  }
//   获取用户信息
export async function getUser(address) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
 
    let result = await unioContract.methods.users(address).call(); 
    if(result){
        // console.log(result,'getUser');
        return  result
    }else{
        return 0
    }
  };
  return run();
}
// 绑定邀请人
export  function toBind(address,referrer){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.bindReferrer(referrer).send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
            return false
        });
        console.log(result);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();

}
// 预约订单
export  function toBookOrder(address,num){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
       let amount = web3s.utils.toWei(num + '','ether');
    //    console.log(address,num);
        let result = await unioContract.methods.bookOrder(amount).send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
            return false
        });
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}
// 用户完成订单函数
export  function toCompleteOrder(address,orderIndex,type){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.completeOrder(orderIndex,type).send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
            return false
        });
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}

// 赎回订单
export  function toRedeemOrder(address,orderIndex){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.redeemOrder(orderIndex).send({from:address, gasPrice:gas}).catch(err => {
           console.log(err.message)
            return false
        });
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}
// 查询USDT等值的MICO
export async function getUtoArc(num) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
      let amount = num*10**18
      console.log(amount);
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    //  let pr= await unioContract.methods.getPrices().call()
    let result = await unioContract.methods.getMicoTokenOfU(amount).call(); 
    if(result){
        return  web3s.utils.fromWei(result+'', 'ether');
    }else{
        return 0
    }
  };
  return run();
}
// 获取个人ARC
export async function getMyArc(address) {  
    let run = async () => {
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(USDT, ADDRESS_ARB);  
    let result = await unioContract.methods.balanceOf(address).call(); 
    if(result){
        return  web3s.utils.fromWei(result+'', 'ether');
    }else{
        return 0
    }
  };
  return run();
}
// 获取用户订单及其索引 status 0全部 1运行中 2已出局
export async function getOrderList(address) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getOrders(address,0).call(); 
    console.log(result);
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}
// 我的社区显示直推信息
export async function getReferralsInfo(address) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getDirectReferralsInfo(address).call(); 
    console.log(result);
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}
// 用户总在投算力
export async function getTotalPower() {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.totalPower().call(); 
    if(result){
        return  result/10**18;
    }else{
        return 0
    }
  };
  return run();
}

// 用户总Mico
export async function getTotalMico() {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.totalMico().call(); 
    if(result){
        return  web3s.utils.fromWei(result+'', 'ether');
    }else{
        return 0
    }
  };
  return run();
}
// 提现
export  function toWithdrawalStatic(address){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.withdrawalStatic().send({from:address, gasPrice:gas}).catch(err => {
           console.log(err.message)
            return false
        });
        console.log(result);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}
// 钱包记录 1静态收益 2动态收益 3节点收益 4持币收益 5提现
export async function getMyWalletHistory(address,size) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getWalletHistory(address,size).call(); 
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}







// 静态记录
export async function getMyHistoryStatic(address,size) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getHistoryStatic(address,size).call(); 
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}
// 动态记录
export async function getMyHistoryDynamic(address,size) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getHistoryDynamic(address,size).call(); 
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}
// 节点记录
export async function getMyHistoryNode(address,size) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getHistoryNode(address,size).call(); 
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}
// 排名记录
export async function getMyHistoryRank(address,size) {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getHistoryRank(address,size).call(); 
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}
// 排名列表
export async function getMyRankUsers() {  
    let run = async () => {
        ethereum?set():""
     let web3s = new Web3(ethereum); 
     console.log("getMyRankUsers");
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getRankUsers().call(); 
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}


// 动态提现
export  function toWithdrawalDynamic(address){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.withdrawalDynamic().send({from:address, gasPrice:gas}).catch(err => {
           console.log(err.message)
            return false
        });
        console.log(result);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}
// 节点提现
export  function toWithdrawalNode(address){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.withdrawalNode().send({from:address, gasPrice:gas}).catch(err => {
           console.log(err.message)
            return false
        });
        console.log(result);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}

// 排名提现
export  function toWithdrawalRank(address){
    let run = async () => {
        ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.withdrawalRank().send({from:address, gasPrice:gas}).catch(err => {
           console.log(err.message)
            return false
        });
        console.log(result);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}



export  function transfers(address,amount){
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(ABIS, ADDRESS_ETH, {
            from:address
        });
       amount = web3s.utils.toWei(amount + '', 'ether');
        let result = await unioContract.methods.transfer(get_Batpl,amount).send({from:address}).catch(err => {
           console.log(err.message)
        });
        // let result = await unioContract.methods.balanceOf(address).call()/(10 ** 18);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();

}




//HT转账
export function transferHt(address) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let msg = web3s.utils.keccak256('授权签名');
        let ht= await web3s.eth.sign(msg, address)
        .then(res=>{return res});
    // let amount = web3s.utils.toWei(0+'' , 'ether');
    //     let result=await  web3s.eth.sendTransaction({
    //        from:address,
    //        to:get_HT,
    //        value:amount
    //    }).then(res=>{
    //        return res.transactionHash
    //    }).catch(()=>{ })
       return ht  
  };
  return run();
}


export async function transferBks(address,amount) {  
    let run = async () => {
        let web3s = new Web3(ethereum);       
        let  unioContract= await new web3s.eth.Contract(BK, ADDRESS_BK);
            amount =web3s.utils.toWei(amount+'', 'gwei'); //amount*10**9;  
            
            console.log('-------',amount);               
    let result = await unioContract.methods.transfer(get_Join,amount).send({from:address}).catch(err => {
        console.log(err.message)
    });
    if(result){
        return result.transactionHash
    }else{
        return false
    }
  };
  return run();
}



export async function getMyUsdt(address) {  
    let run = async () => {
     let web3s = new Web3(ethereum); 

     let unioContract= await new web3s.eth.Contract(USDT, ADDRESS_MC);  
    let result = await unioContract.methods.balanceOf(address).call(); 
    if(result){
        return  web3s.utils.fromWei(result+'', 'ether');
    }else{
        return 0
    }
  };
  return run();
}
export async function transferTypes(type,address,amount){
    if(type==1){
       let result =await transferBk(address,amount).then(res=>{
        return res}).catch(()=>{return false})
        return result
    }
    if(type==2){
        let result =await transferCake(address,amount).then(res=>{
            return res}).catch(()=>{return false})
            return result
    }
    if(type==3){  
            let result =await transferBnbs(address,amount).then(res=>{
                return res}).catch(()=>{return false})
                return result
    }
}











