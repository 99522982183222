export const m = {
  lqlj: "Confirm claim ",
  sqinfo: "To use the features of your personal account, please use Wallet authorization ",
  sqbtn: "Wallet Authorization ",
  sqload: "Authorizing..." ,
  dltt: "Sign in to your account ",
  dlinfo: "To use the features of your personal account, click on the Link Wallet ",
  dlbtn: "Linked Wallet ",
  bdtt: "Bind parent ",
  bdinfo: "Please enter the referrer's wallet address ",
  bdbtn: "Confirm binding ",
  bdload: "binding..." ,
  bangsuc:"Binding successful",
  lqsg: "Claim successful ",
  lqsb: "Claim Failure ",
  day : "day ",
  shi:" Hour ",
  fen: "Fen!",
  miao:" Seconds ",
  fzcg:" Copy success ",
  fzsb:" Replication failure ",
  yhdj:" User Level ",
  zwkf:"Tip: Not yet open!",  
  mysj:" No data ",  
  wdyqlj:" My invitation link ",
  wdtjdz:"My recommended address",
  fz:" Copy ",
  home:"Home",
  qr:" Confirm ",
ny:" You have ",
wcqbqr:" Complete wallet confirmation ",
dqsl:" Authorized Quantity ",
cyje:" Participation amount ",
qsrcyje:" Please enter the participation amount ",
zfsl:" Payment quantity ",
qbye:" wallet balance ",
ljcy:" Get Involved Now ",
wdzc:" My Assets ",
klqzc:" Claimable Assets ",
jrsy:" Earnings Today ",
ljsy:" Cumulative Earnings ",
lq:" Claim ",
qrlq:" Confirm collection ",

wddd:' My Order ',
yxz:" In operation ",
ycj:" Out ",
cysj:" Participation Time ",
zfje:" Payment amount ",
cjjd:" Outgoing node ",

wdsq:" My Community ",
wddj:" My level ",
sfjd:" Node or not ",
shi:" Yes ",
fou:" No ",
kgdj:" Miner Level ",
wu:" No ",
cjkg:" Junior Miner ",
zjkg:" Intermediate Miner ",
gjkg:" Senior Miner ",
cjkgs:" Super Miner ",
cskg:" Genesis Miners ",
slzx:" Computing Power Center ",
grztslz:" Individual in the calculation of power value ",
grztrslz:" Personal Total Input Computing Power ",
ydztslz:" Generation is calculating power values ",
erztslz:" The second generation is calculating the power value ",
xqztslz:" The community is calculating the power value ",
sqztslz:" The community is calculating power values ",
wdfx:" My Share ",
wxyh:"Invalid user",
yxyh:"Active user",
cycg:"Participation success",
cysb:"Participation failure"

}